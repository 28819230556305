import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private themes: any = {
    cn: {
        buttonBg: "#06c", // Blue
        buttonTextColor: "#fff",
     //   buttonRadius: "5px",
        textFont: "Open Sans, Helvetica, Arial, sans-serif",
      }
  };

  private currentTheme = new BehaviorSubject<any>(this.themes.cn);
  theme$ = this.currentTheme.asObservable();

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    debugger
    this.renderer = rendererFactory.createRenderer(null, null);
    this.observeDOMChanges(); // Watch for new components
  }

  setTheme(themeName: string) {
    const theme = this.themes[themeName] || this.themes["bid"];
    this.currentTheme.next(theme);

    // Apply font globally
    document.body.style.fontFamily = theme.textFont;

    // Apply button styles across the project
    if (themeName == 'cn')
    this.applyButtonStyles(theme);
  }

  private applyButtonStyles(theme: any) {
    if(localStorage.getItem('partner') == 'cn'){
    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => {
      this.renderer.setStyle(button, "background-color", theme.buttonBg);
      this.renderer.setStyle(button, "color", theme.buttonTextColor);
      this.renderer.setStyle(button, "font-family", theme.textFont);
      this.renderer.setStyle(button, "border-radius", "1px");
    });
}
  }

  // 📌 This function watches for new buttons added dynamically
  private observeDOMChanges() {
    const observer = new MutationObserver(() => {
      const theme = this.currentTheme.getValue();
      this.applyButtonStyles(theme); // Reapply styles whenever DOM updates
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
}
