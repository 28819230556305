import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/configurations/global-constants';
import { HttpCallService } from 'src/app/services/http-call.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private httpCallService: HttpCallService) {}

  login(loginPayload: any) {
    debugger
    return this.httpCallService.postWithGuestUser(
      GlobalConstants.BB_SERVICE,
      GlobalConstants.BB_AUTH_LOGIN,
      loginPayload
    );
  }

  getUserProfile() {
    return this.httpCallService.getWithHttpParams(
      GlobalConstants.BB_SERVICE,
      GlobalConstants.BB_GET_USER_PROFILE,
      []
    );
  }

  forgotPassword(email: any) {
    return this.httpCallService.postWithGuestUserBodySuffix(
      GlobalConstants.BB_SERVICE,
      GlobalConstants.BB_USER_FORGOT_PASSWORD,
      '',
      '?email=' + email
    );
  }
}
