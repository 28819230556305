import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, from } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private router: Router, private userService: UserService) {}

  logout() {
    this.removeLs();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  // setLocalStorage(val: any) {
  //   localStorage.setItem('BB_USER_TOKEN', JSON.stringify(val));
  // }

  getLocalStorage(val: any) {
    try {
      const lsValue = localStorage.getItem(val);
      if (!lsValue) {
        return undefined;
      }
      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getToken() {
    try {
      const lsValue = this.getLocalStorage('BB_USER_TOKEN');
      if (!lsValue) {
        return undefined;
      }
      return lsValue.token;
    } catch (error) {
      return undefined;
    }
  }

  getUser() {
    try {
      const lsValue = this.getLocalStorage('BB_USER_TOKEN');
      if (!lsValue) {
        return undefined;
      }
      return lsValue;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private removeLs() {
    localStorage.removeItem('BB_USER_TOKEN');
  }

  loadUserToken(): Observable<any> {
    let userDetails = this.getToken();
    if (userDetails) {
      return of(userDetails);
    } else {
      // this.logout();
      return of(undefined);
    }
  }
}
