import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

// language list

import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { AuthService } from './modules/common/auth';
import { first } from 'rxjs';
import { ThemeService } from './services/theme.service';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  // Define theme styles
  themes: any = {
    bid: {
      buttonBg: "#06c", // Blue
      buttonTextColor: "#fff",
   //   buttonRadius: "5px",
      textFont: "Open Sans, Helvetica, Arial, sans-serif",
    },
    cn: {
      buttonBg: "#e91e63", // Pink
      buttonTextColor: "#000000",
      buttonRadius: "30px",
      textFont: "Courier New, monospace",
    }
  };
  // Default styles
  buttonStyles: any = {};
  textStyles: any = {};

  constructor(private modeService: ThemeModeService,
      private authService: AuthService,
      private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.authService
      .getUserProfile()
      .pipe(first())
      .subscribe((res: any) => {
        debugger
        if (res.USER_PROFILE == 'PROFILE_DEFAULT'){
          localStorage.setItem('partner', 'bid')
      //    this.applyTheme('bid');
    //  this.themeService.setTheme('bid')


        } else {
          localStorage.setItem('partner', 'cn')
          // this.applyTheme('cn');
          this.themeService.setTheme('cn')
        }
console.log('$$$$$$', res);

      })
    this.modeService.init();
  }
  applyTheme(themeName: string) {
    debugger
    const theme = this.themes[themeName] || this.themes["bid"]; // Default to 'bid'


    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => {
      (button as HTMLElement).style.backgroundColor = theme.buttonBg;
      (button as HTMLElement).style.color = theme.buttonTextColor;
      (button as HTMLElement).style.fontFamily = theme.textFont;
      // (button as HTMLElement).style.borderRadius = theme.buttonRadius;
      // (button as HTMLElement).style.border = "none";
      // (button as HTMLElement).style.padding = "10px 20px";
      // (button as HTMLElement).style.cursor = "pointer";
    });
  }


}
