import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.storageService.getToken() || null;
    if (token && !request.headers.has('Authorization')) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.log(event);
            // do stuff with response if you want
          }
        },
        (error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {
            //------------------------------ client-side error--------------------------- //
            errorMessage = `1Error: ${error.error.message}`;
            // alert
          } else {
            //----------------------------- server-side error----------------------------//
            errorMessage = `Error Code: ${error.status}\n Message:${error.message}`;
            // if(error.status == 0){
            //    alert("ERROR!! ","Internal Server Error")
            // }
            //console.log(error)
            if (error.status == 401) {
              // alert("unauthorized");
              this.storageService.logout();
            } else if (error.status != 0 && error.status != 500) {
              //  alert("ERROR!! ",`Error Code: ${error.status}\n`+"Http Response Failed")
            }
            if (error.status == 500) {
              // alert("ERROR! ",`Something went wrong, \n`+`Pls try again or contact administrator\n`)
            }
          }

          if (error instanceof TimeoutError) {
            //  alert("ERROR!!","Connection Time Out!!")
          }

          return throwError(() => new Error(errorMessage));
        }
      )
    );
  }
}
