import { Injectable } from '@angular/core';
import { BbApiService } from './bb-api.service';
import { GlobalConstants } from './global-constants';

@Injectable({
  providedIn: 'root',
})
export class ApiEndpointService {
  constructor(private bbApiService: BbApiService) {}

  /**
   * return respective url based on paramenter
   * @param module resquesting api modules
   * @param url resquesting api url
   * @param args resquesting query string args
   */
  getUrl(module: string, url: string, args?: any) {
    let queryString: string = '';
    let endPoint: string = '';

    //checks
    if (args != null && Object.keys(args).length > 0) {
      queryString = Object.keys(args)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(args[key]);
        })
        .join('&');
    }

    //check for bb service
    if (module == GlobalConstants.BB_SERVICE) {
      endPoint = this.bbApiService.getUrl(url);
      //console.log(endPoint)
    }

    //return
    return queryString != '' ? endPoint + '?' + queryString : endPoint;
  }
}
