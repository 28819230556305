import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ApiEndpointService } from 'src/app/configurations/api-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class HttpCallService {
  httpOptions: {};
  _httpHeaders: any;
  userDetails: any;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private apiUrl: ApiEndpointService
  ) {
    this.userDetails = this.userService.getUserDetails();
  }

  getHttpGuestHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpOptions;
  }

  getHttpHeaders(params?: any) {
    this.userDetails = this.userService.getUserDetails();

    if (params) {
      this.httpOptions = {
        params: params,
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userDetails.token}`,
        }),
      };
      return this.httpOptions;
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userDetails.token}`,
        }),
      };
      return this.httpOptions;
    }
  }

  getHttpGuestUser(
    serviceName: string,
    endPoint: string,
    parameter?: any
  ): Observable<any> {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint, parameter);

    return this.http.get(endPointUrl, this.getHttpGuestHeaders());
  }

  getWithHttpParams(serviceName: string, endPoint: string, params?: any) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.get(endPointUrl, this.getHttpHeaders(params));
  }

  getWithSuffixHttpParams(
    serviceName: string,
    endPoint: string,
    suffix: any,
    params?: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.get(endPointUrl, this.getHttpHeaders(params));
  }

  postWithGuestUser(serviceName: string, endPoint: string, body: any) {
    debugger
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.post(endPointUrl, body, this.getHttpGuestHeaders());
  }

  postWithGuestUserBodySuffix(
    serviceName: string,
    endPoint: string,
    body: any,
    suffix: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.post(endPointUrl, body, this.getHttpGuestHeaders());
  }

  postHttpResponse(serviceName: string, endPoint: string, body: any) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.post(endPointUrl, body, this.getHttpHeaders());
  }

  postWithHttpParams(serviceName: string, endPoint: string, params: any) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.post(endPointUrl, null, this.getHttpHeaders(params));
  }

  postHttpResponseWithSuffix(
    serviceName: string,
    endPoint: string,
    suffix: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    console.log(endPointUrl);
    return this.http.post(endPointUrl, null, this.getHttpHeaders());
  }

  postHttpResponseWithBodySuffix(
    serviceName: string,
    endPoint: string,
    body: any,
    suffix: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.post(endPointUrl, body, this.getHttpHeaders());
  }

  putWithSuffixHttpParams(
    serviceName: string,
    endPoint: string,
    suffix: any,
    params: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.put(endPointUrl, null, this.getHttpHeaders(params));
  }

  putHttpResponse(serviceName: string, endPoint: string, body: any) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.put(endPointUrl, body, this.getHttpHeaders());
  }

  deleteHttpResponse(serviceName: string, endPoint: string) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.delete(endPointUrl, this.getHttpHeaders());
  }

  postWithHttpParamsSuffix(
    serviceName: string,
    endPoint: string,
    suffix: any,
    body: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.post(endPointUrl, body, this.getHttpHeaders());
  }

  putWithSuffix(serviceName: string, endPoint: string, suffix: any, body: any) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.put(endPointUrl, body, this.getHttpHeaders());
  }

  postUploadFile(serviceName: any, endPoint: string, formdata: FormData) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint);
    return this.http.post(endPointUrl, formdata, this.getHttpHeaders());
  }

  getWithSuffixHttpParamsPolling(
    serviceName: string,
    endPoint: string,
    suffix: any,
    params?: any
  ) {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.get(endPointUrl, this.getHttpHeaders(params));
  }

  getImage(
    serviceName: string,
    endPoint: string,
    suffix: any,
    params?: any
  ): Observable<Blob> {
    var endPointUrl = this.apiUrl.getUrl(serviceName, endPoint) + suffix;
    return this.http.get(endPointUrl, { responseType: 'blob' });
  }
}
